import { popup_pi_bonus } from 'assets/images/popup';
import { popup_nft } from 'assets/images/popup_deposit_bonus';

import { Box, styled } from '@mui/material';

// ----------------------------------------------------------

export const DepositBonusWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: `url(${popup_nft}) `,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },

  'div:first-child': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    svg: {
      cursor: 'pointer',
      [theme.breakpoints.down(450)]: {
        width: 18,
        height: 18,
      },
    },
  },

  button: {
    width: 300,
    height: 48,
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.background.main,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      width: 260,
      height: 38,
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      width: 200,
      height: 30,
      fontSize: 14,
    },
    [theme.breakpoints.down(450)]: {
      width: 170,
      height: 25,
      fontSize: 12,
    },
  },
}));

// PI GAME

export const SignUpPiWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  background: `url(${popup_pi_bonus}) `,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    height: 450,
    padding: theme.spacing(2.5),
  },
  [theme.breakpoints.down('sm')]: {
    height: 370,
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down(450)]: {
    height: 280,
    padding: theme.spacing(1.5),
  },

  'div:first-child': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    svg: {
      cursor: 'pointer',
      [theme.breakpoints.down(450)]: {
        width: 18,
        height: 18,
      },
    },
  },

  button: {
    fontSize: 16,
    fontWeight: 700,
    color: '#2E2C5E',
    lineHeight: 'normal',
    padding: theme.spacing(1.75, 6),
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: theme.spacing(1.25, 4),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 10,
      padding: theme.spacing(1, 3.5),
    },
  },
}));
