import { SvgIcon } from '@mui/material';

export function LogoOhplayMB(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120.75 120.75"
      {...props}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M118.87,45.38l-16.14,6.2a43.72,43.72,0,0,1-.52,19.79l15.8,7a60.73,60.73,0,0,0,.86-33Zm-47.5,56.83a43.61,43.61,0,0,1-22,0l-7,15.8a60.72,60.72,0,0,0,36.07,0ZM60.37,0a60.31,60.31,0,0,0-18,2.74l7,15.79a43.61,43.61,0,0,1,22,0l7-15.79A60.38,60.38,0,0,0,60.37,0ZM18.53,49.37l-15.79-7a60.7,60.7,0,0,0-.86,33L18,69.17a43.55,43.55,0,0,1,.51-19.8Z"
            fill="#ff6838"
          />
          <path
            d="M82.17,31.2c-1.5.58-3,1.09-4.54,1.55a61,61,0,0,1-34.51,0q-2.46-.73-4.89-1.68a67.17,67.17,0,0,1,6.61,29.3,67.21,67.21,0,0,1-6.61,29.31c1.62-.63,3.25-1.2,4.9-1.68a61,61,0,0,1,34.5,0c1.52.45,3,1,4.54,1.54a68.23,68.23,0,0,1,0-58.34ZM67.38,66.87a5.94,5.94,0,0,1-5.91-1.33,4.31,4.31,0,0,0-.72-.57c.1.36.18.72.31,1.06a9.56,9.56,0,0,0,3.2,4.15l.79.63q-2.29-.18-4.65-.18c-1.72,0-3.42.08-5.1.21l1-.74a9.1,9.1,0,0,0,3.35-4.89,2.17,2.17,0,0,0,0-.25l-.13-.12a3.27,3.27,0,0,1-.35.48,6.05,6.05,0,0,1-8.18.46,4.54,4.54,0,0,1-.84-6.44A16.27,16.27,0,0,1,52.4,57c1.26-1.11,2.6-2.13,3.89-3.21.49-.41,1-.85,1.42-1.3a9.25,9.25,0,0,0,2.49-3.71,11.24,11.24,0,0,0,3.63,4.77c1.14,1,2.35,1.88,3.49,2.87a28.34,28.34,0,0,1,2.46,2.31C72.74,62,70.81,65.84,67.38,66.87Zm18.13-6.5a60.48,60.48,0,0,1,17.55-42.68,2.28,2.28,0,0,1,.22-.22l-.22.21A60.7,60.7,0,0,1,89.17,28a62,62,0,0,0-8.9,32.38,62.09,62.09,0,0,0,8.9,32.39,60.87,60.87,0,0,1,13.89,10.3l.22.22-.22-.22A60.51,60.51,0,0,1,85.51,60.37ZM31.16,27.76A60.39,60.39,0,0,1,17.7,17.68l-.23-.21a2.28,2.28,0,0,1,.22.22A60.48,60.48,0,0,1,35.24,60.37a60.48,60.48,0,0,1-17.55,42.68l-.22.23.23-.22A60.39,60.39,0,0,1,31.16,93a62,62,0,0,0,9-32.61A62.07,62.07,0,0,0,31.16,27.76Z"
            fill="#ff6838"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
