import { SvgIcon } from '@mui/material';

export function IconFBFooter(props: any) {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_34_1374)">
        <mask
          id="mask0_34_1374"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <path d="M40 0H0V40H40V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_34_1374)">
          <path
            d="M20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0Z"
            fill="#2F2B60"
          />
        </g>
        <path
          d="M24.6002 21.2002L25.1002 17.6002H21.7002V15.3002C21.7002 14.3002 22.2002 13.4002 23.7002 13.4002H25.3002V10.4002C25.3002 10.4002 23.9002 10.2002 22.5002 10.2002C19.7002 10.2002 17.8002 11.9002 17.8002 15.0002V17.7002H14.7002V21.3002H17.8002V29.9002C18.4002 30.0002 19.1002 30.0002 19.7002 30.0002C20.3002 30.0002 21.0002 29.9002 21.6002 29.9002V21.3002H24.6002V21.2002Z"
          fill="#11A9FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_34_1374">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
