import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import Button from 'common/Button';
import { DialogAnimate } from 'components/animate';
import ReactGA from 'react-ga4';

import { Box, useTheme } from '@mui/material';

import { SOCIAL_COMMUNITY_LINK } from 'constants/index';
import { useLocales } from 'locales';
import { DepositBonusWrapper } from './style';

// ----------------------------------------------------------------

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

const PopupBonus = ({ open, onClose }: Partial<Props>) => {
  const theme = useTheme();
  const { translate } = useLocales();

  const handleClaimNowClick = () => {
    window.open(SOCIAL_COMMUNITY_LINK.TELEGRAM_COMMUNITY_OHPLAY, '_blank');
    onClose();
    ReactGA.event({
      category: 'Popup bonus Click',
      action: 'Button Claim Now popup bonus Ohplay click',
    });
  };

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        aspectRatio: '1/1',
        [theme.breakpoints.down('md')]: {
          width: 500,
        },
        [theme.breakpoints.down('sm')]: {
          width: 400,
        },
        [theme.breakpoints.down(450)]: {
          width: 350,
          height: 350,
        },
      }}
    >
      <DepositBonusWrapper>
        <Box>
          <CloseCircle onClick={onClose} />
        </Box>

        <Button type="button" onClick={() => handleClaimNowClick()}>
          {translate('claim_now')}
        </Button>
      </DepositBonusWrapper>
    </DialogAnimate>
  );
};

export { PopupBonus };
