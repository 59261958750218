import {
  IconDiscord,
  IconFBFooter,
  IconInsta,
  IconTelegram,
  IconTwitterX,
} from 'assets/icons';
import { SOCIAL_COMMUNITY_LINK } from 'constants/index';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import { FooterContactWrapper } from './style';

// ----------------------------------------------------------------

const FooterContact = () => {
  const [isHoverTele, setIsHoverTele] = useState(false);
  const [isHoverFB, setIsHoverFB] = useState(false);

  return (
    <FooterContactWrapper>
      <Box
        component="li"
        onMouseEnter={() => setIsHoverTele(true)}
        onMouseLeave={() => setIsHoverTele(false)}
      >
        <IconTelegram />

        {isHoverTele && (
          <Box>
            <Link
              to={SOCIAL_COMMUNITY_LINK.TELEGRAM_CHANEL_OHPLAY}
              target="_blank"
            >
              Chanel
            </Link>
            <Link
              to={SOCIAL_COMMUNITY_LINK.TELEGRAM_COMMUNITY_OHPLAY}
              target="_blank"
            >
              Community
            </Link>
          </Box>
        )}
      </Box>

      <Box component="li">
        <Link to={SOCIAL_COMMUNITY_LINK.DISCORD_OHPLAY} target="blank">
          <IconDiscord />
        </Link>
      </Box>

      <Box component="li">
        <Link to={SOCIAL_COMMUNITY_LINK.TWITTER_OHPLAY} target="blank">
          <IconTwitterX />
        </Link>
      </Box>

      <Box component="li">
        <Link to={SOCIAL_COMMUNITY_LINK.INSTA_OHPLAY} target="blank">
          <IconInsta />
        </Link>
      </Box>

      <Box
        component="li"
        onMouseEnter={() => setIsHoverFB(true)}
        onMouseLeave={() => setIsHoverFB(false)}
      >
        <IconFBFooter />

        {isHoverFB && (
          <Box>
            <Link
              to={SOCIAL_COMMUNITY_LINK.FACEBOOK_CHANEL_OHPLAY}
              target="_blank"
            >
              Chanel
            </Link>
            <Link
              to={SOCIAL_COMMUNITY_LINK.FACEBOOK_COMMUNITY_OHPLAY}
              target="_blank"
            >
              Community
            </Link>
          </Box>
        )}
      </Box>
    </FooterContactWrapper>
  );
};

export { FooterContact };
